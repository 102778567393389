import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import { SingleCompanyGrid } from './index';
import SingleVideoGrid from './SingleVideoGrid';



const CustomCarousel = ({
     groupId,
     listingData,
     EnableNumber,
     disabledLink,
     itemType,
     insights,
}) => {

     const [SlidesPerView, setSlidesPerView] = useState(5);
     const itemSize = () => {
          if (window.innerWidth < 480) {
               setSlidesPerView(1);
          } else if (window.innerWidth < 680) {
               setSlidesPerView(2);
          } else if (window.innerWidth < 880) { 
               setSlidesPerView(3);
          } else {
               setSlidesPerView(5);
          }
     }
     useEffect(() => {
          itemSize();
          window.addEventListener('resize', function () {
               itemSize();
          });
          // console.warn('SlidesPerView >>>> ', SlidesPerView, window.innerWidth);
     }, [SlidesPerView])


     // console.log('listingData >> ' + JSON.stringify(listingData));

     // const companiesData = [...new Map(listingData.map(item => [groupId, item])).values()]
     const companiesData = listingData.filter((item) => item.group_id === groupId);
     const companiesDataIds = listingData.map((item) => item.company_id);
     const uniqueCompaniesData = [...new Map(companiesData.map(item => [item.companies['company_id'], item])).values()]; // [ 'A', 'B'] 
     // console.log(groupId);
     // console.log('companiesData ??' + JSON.stringify(companiesData));
//     useEffect(() => {
//      console.log(uniqueCompaniesData);
//      }, [])

     return (
          <>
               <div className="carousel-container">
                    <Swiper
                         // install Swiper modules
                         // onSwiper={(swiper) => console.log(swiper)}
                         // onSlideChange={() => console.log('slide change')}
                         className='external-buttons'
                         modules={[Navigation]}
                         spaceBetween={10}
                         
                         slidesPerView={SlidesPerView}
                         navigation
                         pagination={{ clickable: true }}
                         scrollbar={{ draggable: true }} 
                    >
                         {
                              uniqueCompaniesData.map((company, i) => {
                                    {/* console.log(company?.companies) */}
                                   return (
                                        <SwiperSlide key={i} style={{

                                        }}>
                                             {
                                                  itemType === 'video' ?
                                                       (<>
                                                            <SingleVideoGrid itemData={company} />
                                                       </>)
                                                  :
                                                       <SingleCompanyGrid insights = {insights} disabledLink={disabledLink} numCount={EnableNumber ? i + 1 : null} companyData={company?.companies} />
                                             }
                                        </SwiperSlide>
                                   )
                              })
                         }


                    </Swiper>
               </div>

          </>
     )
}

export default CustomCarousel