import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { crtUserId } from "../../../constants/helpers";
import { AddToMylistAction, MyBlogListAction, RemoveToMylistAction } from "../../../redux/actions/VdrAction";
import { BiTrash } from "react-icons/bi";

const DetailComponent = ({ item, insigths }) => {

const navigate = useNavigate();  
const { data: MyBlogListData, loading: MyBlogListLoading } = useSelector((state) => state.MyBlogListReducer);

const dispatch = useDispatch();
const [MyFavorite, setMyFavorite] = useState([])
const myFavFun = () => {
  if(MyBlogListData?.data && MyBlogListData.data.length > 0){
      let a0 = MyBlogListData.data.map((item)=>item?.blogdata[0].blog_id)
      setMyFavorite(a0)
  }else{
    setMyFavorite([])
  }
}
const userId = crtUserId();
const addtolist = async (type) => {
  if(type === 1)
  {
   let vid_params = { user_id:userId, blog_id: item?.blog_id,type:"blog"}
   await dispatch(AddToMylistAction(vid_params));
  } else{
   let r_params = { user_id:userId, blog_id:item?.blog_id,type:"blog"}
   await dispatch(RemoveToMylistAction(r_params));
  }
  let prams = { "user_id": userId,"type":"blog"};
  dispatch(MyBlogListAction(prams));

}


useEffect(() => {
  if(!MyBlogListLoading){
    myFavFun();
  }
}, [MyBlogListLoading]);




  return (
    <>
      <div className="card_item">
        
        <Card sx={{ width: "100%" }}>
          <CardActionArea onClick={()=> navigate(`${insigths ? '/insights/' : '/blogs/'}${item?.slug}`)}>
            <CardMedia
              component="img"
              height={30}
              // height={50}
              image={item?.imagewithurl}
              alt="blogimg"
              className="cardImage"
            />
          </CardActionArea>
 
          <CardActions>
            <div className="date_addtolist">
              <div className="date" style={{
                fontSize: '.7rem'
              }}>
                {/* {moment(item?.created_at).format("DD MMM, YYYY")} */}
                {moment(item?.updated_at).format("DD MMM, YYYY")}
              </div>
              <div className="addtolist">
                {/* <Button className="res_crd_btn" size="small">
                  <AddIcon style={{
                    fontSize: '.8rem'
                  }} /> Add To List
                </Button> */}

                {
                  MyFavorite.includes(item?.blog_id) ?
                    <Button  onClick={()=>addtolist(Number(0))} className='addToList' size="small"><BiTrash style={{ 
                      marginRight:'.2rem'
                    }} />  Remove From List</Button>
                    :
                    <Button  onClick={()=>addtolist(Number(1))} className='addToList' size="small"><AddIcon style={{
                      fontSize: '.8rem'
                    }} /> Add To List</Button>
                }
              </div>
            </div>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default DetailComponent;
