import { BiTrash } from "react-icons/bi"; 
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { AiFillPlayCircle } from "react-icons/ai";
import moment from "moment";
// import { get_vimeo_thumbnail } from "../../../constants/helpers";

import $ from 'jquery';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { VDRMediaAddToFavouriteAction, VDRMediaMyFavouriteListAction } from "../../../redux/actions/VdrAction";
import { useDispatch } from "react-redux";
import { crtUserId } from "../../../constants/helpers";


const VideoComponent = ({ item, MyFavourite }) => {

  // console.log('item ', item)

  const [ThumbData, setThumbData] = useState("")

  
const get_vimeo_thumbnail = (url, imgId, size = null, callback = null) => {
  axios.get(`https://vasudeep.com:8084/https://player.vimeo.com/video/${url[0]}/config?h=${url[1]}`)
      .then(function (response) {
          var data = response.data;
          setThumbData(data.video.thumbs.base);
      })
      .catch(function (error) {

      });
}
const navigate = useNavigate();
const dispatch = useDispatch();


const addtolist = async (type) => {
  let vid_params = { webuserId: crtUserId(),video_id: item?.videoId, inputType: type}
  await dispatch(VDRMediaAddToFavouriteAction(vid_params));
  let prams = { "webuserId": crtUserId() };
  dispatch(VDRMediaMyFavouriteListAction(prams));
};


useEffect(() => {
// console.log(item?.videoId, typeof(item?.videoId), MyFavourite)
  var videoThumbnail;
  var vCode = item.videoCode ? item.videoCode.split('/') : [];
  if (item.videoType === 'vimeo') {
      videoThumbnail = get_vimeo_thumbnail(vCode, `imgId${vCode[0]}`);
  } else {
      videoThumbnail = `https://img.youtube.com/vi/${item.videoCode}/mqdefault.jpg`;
      setThumbData(videoThumbnail)
  }
}, [])

  return (
    <>
      <div className="card_item">
        
        <Card sx={{ width: "100%" }}>
          <CardActionArea onClick={()=> navigate(`/videos/${item?.videoId}`)}>

            <div className="cardimg cardimg1 ">
            <CardMedia
              component="img"
              height={50}
              image={ThumbData}
              alt="blogimg"
              className="cardImage"
            />
            <div className="sc_vid_ovl"></div>
            <div className="playbutton"> <AiFillPlayCircle /></div>
            </div>

            <CardContent sx={{ 
              paddingTop:'5px !important',
              paddingBottom:'0px !important'
             }}>
              <Typography gutterBottom variant="h5" component="div">
                <h3> {item?.videoTitle}</h3>
              </Typography>
              {/* <Typography variant="body2" color="text.secondary">
                <p>{item?.videoDescription}</p>
              </Typography> */}
            </CardContent>
          </CardActionArea>

          <CardActions>
            <div className="date_addtolist">
              <div className="date" style={{
                fontSize: '.7rem'
              }}>
                {moment(item?.dateTime).format("DD MMM, YYYY")}
              </div>

              <div className="addtolist">
              {
                MyFavourite.includes(item?.videoId) ? 
                  <>
                    <Button  onClick={()=>addtolist(Number(0))} className="res_crd_btn" size="small">
                      <BiTrash style={{ 
                        marginRight:'.2rem'
                       }} /> Remove From List
                    </Button>
                  </>
                  :
                  <>
                    <Button  onClick={()=>addtolist(Number(1))} className="res_crd_btn" size="small">
                      <AddIcon style={{
                        fontSize: '.8rem'
                      }} /> Add To List
                    </Button>
                  </>
              }

                {/* <Button className="res_crd_btn" size="small">
                  <AddIcon /> Add To List
                </Button> */}
              </div>
            </div>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default VideoComponent;
