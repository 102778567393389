import axios from "axios";
import publicIp from 'public-ip';


import {
    VDR_GROUPS_REQUEST,
    VDR_GROUPS_SUCCESS,
    VDR_GROUPS_FAIL,
    VDR_COMPANY_REQUEST,
    VDR_COMPANY_SUCCESS,
    VDR_COMPANY_FAIL,
    VDR_LEVEL_REQUEST,
    VDR_LEVEL_SUCCESS,
    VDR_LEVEL_FAIL,
    VDR_HOME_REQUEST,
    VDR_HOME_SUCCESS,
    VDR_HOME_FAIL,
    VDR_UPLOAD_IMAGE_REQUEST,
    VDR_UPLOAD_IMAGE_SUCCESS,
    VDR_UPLOAD_IMAGE_FAIL,
    PUBLIC_IP_REQUEST,
    PUBLIC_IP_SUCCESS,
    PUBLIC_IP_FAIL,
    VDR_SINGLE_COMPANY_REQUEST,
    VDR_SINGLE_COMPANY_SUCCESS,
    VDR_SINGLE_COMPANY_FAIL,
    PDF_LINK_REQUEST,
    PDF_LINK_SUCCESS,
    MEDIA_MODEL_REQUEST,
    MEDIA_MODEL_OPEN,
    MEDIA_MODEL_CLOSE,
    MEDIA_DATA_REQUEST,
    MEDIA_DATA_SUCCESS,
    MEDIA_DATA_FAIL,
    DATA_20_YEARS_REQUEST,
    DATA_20_YEARS_SUCCESS,
    DATA_20_YEARS_FAIL,
    DATA_20_YEARS_QUARTERLY_REQUEST,
    DATA_20_YEARS_QUARTERLY_SUCCESS,
    DATA_20_YEARS_QUARTERLY_FAIL,
    PEER_TO_PEER_REQUEST,
    PEER_TO_PEER_SUCCESS,
    PEER_TO_PEER_FAIL,
    SORT_DATA_REQUEST,
    SORT_DATA_SUCCESS,
    SORT_DATA_FAIL,
    SORT_DATA_UPDATE_REQUEST,
    SORT_DATA_UPDATE_SUCCESS,
    SORT_DATA_UPDATE_FAIL,
    VDR_HOME_SUCCESS_FILTER,
    FAVORITE_REQUEST,
    FAVORITE_SUCCESS,
    FAVORITE_FAIL,
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FAIL,
    EMPLOYEE_REQUEST,
EMPLOYEE_SUCCESS,
EMPLOYEE_FAIL,
SEGMENT_REQUEST,
SEGMENT_SUCCESS,
SEGMENT_FAIL,
SEGMENT_ASSIGN_REQUEST,
SEGMENT_ASSIGN_SUCCESS,
SEGMENT_ASSIGN_FAIL,
QUARTERLY_REVIEW_REQUEST,
QUARTERLY_REVIEW_SUCCESS,
QUARTERLY_REVIEW_FAIL,
QUARTERLY_SEGMENT_REQUEST,
QUARTERLY_SEGMENT_SUCCESS,
QUARTERLY_SEGMENT_RESET,
QUARTERLY_SEGMENT_FAIL,

DATAROOM_TICKER_REQUEST,
DATAROOM_TICKER_SUCCESS,
DATAROOM_TICKER_FAIL,

FORUM_LIST_REQUEST,
FORUM_LIST_SUCCESS,
FORUM_LIST_FAIL,
FORUM_DETAIL_REQUEST,
FORUM_DETAIL_SUCCESS,
FORUM_DETAIL_FAIL,
QUARTERLY_RESULTS_REQUEST,
QUARTERLY_RESULTS_SUCCESS,
QUARTERLY_RESULTS_FAIL,
QUARTERLY_SNAPSHOT_REQUEST,
QUARTERLY_SNAPSHOT_SUCCESS,
QUARTERLY_SNAPSHOT_FAIL,
SINGLE_COMP_PROFIT_LOSS_REQUEST,
SINGLE_COMP_PROFIT_LOSS_SUCCESS,
SINGLE_COMP_PROFIT_LOSS_FAIL,
SINGLE_COMP_CASHFLOW_REQUEST,
SINGLE_COMP_CASHFLOW_SUCCESS,
SINGLE_COMP_CASHFLOW_FAIL,
VIDEO_LIKE_REQUEST,
VIDEO_LIKE_SUCCESS,
VIDEO_LIKE_FAIL,
VDRMediaAddToFavouriteREQUEST,
VDRMediaAddToFavouriteSUCCESS,
VDRMediaAddToFavouriteFAIL,
VIDEO_COMMENT_REQUEST,
VIDEO_COMMENT_SUCCESS,
VIDEO_COMMENT_FAIL,
VIDEO_COMMENT_LIKE_REQUEST,
VIDEO_COMMENT_LIKE_SUCCESS,
VIDEO_COMMENT_LIKE_FAIL,
NEWSLETTER_LIKE_REQUEST,
NEWSLETTER_LIKE_SUCCESS,
NEWSLETTER_LIKE_FAIL,
NEWSLETTER_COMMENT_ADD,
NEWSLETTER_COMMENT_DELETE,
NEWSLETTER_COMMENT_REQUEST,
NEWSLETTER_COMMENT_SUCCESS,
NEWSLETTER_COMMENT_FAIL,
NEWSLETTER_COMMENT_LIKE_REQUEST,
NEWSLETTER_COMMENT_LIKE_SUCCESS,
NEWSLETTER_COMMENT_LIKE_FAIL,
NEWSLETTER_COMMENT_1_LIKE_SUCCESS,
SINGLE_NEWSLETTER_LIKE_REQUEST,
SINGLE_NEWSLETTER_LIKE_SUCCESS,
SINGLE_NEWSLETTER_LIKE_FAIL,
VDRMediaMyFavouriteListREQUEST,
VDRMediaMyFavouriteListSUCCESS,
VDRMediaMyFavouriteListFAIL,
MyBlogListREQUEST,
MyBlogListSUCCESS,
MyBlogListFAIL,
SingleCompanyBalanceSheetREQUEST,
SingleCompanyBalanceSheetSUCCESS, 
SingleCompanyBalanceSheetFAIL,
SingleCompanypeersREQUEST,
SingleCompanypeersSUCCESS, 
SingleCompanypeersFAIL,
SingleCompanyratiosREQUEST,
SingleCompanyratiosSUCCESS, 
SingleCompanyratiosFAIL,
RemoveToMylistREQUEST,
RemoveToMylistSUCCESS,
RemoveToMylistFAIL,
AddToMylistREQUEST,
AddToMylistSUCCESS,
AddToMylistFAIL,
PEER_RATIOS_REQUEST,
PEER_RATIOS_SUCCESS,
PEER_RATIOS_FAIL,
PEER_TO_PEER_SC_REQUEST,
PEER_TO_PEER_SC_SUCCESS,
PEER_TO_PEER_SC_FAIL,
REPOSITORY_LIST_RESET,
REPOSITORY_LIST_REQUEST,
REPOSITORY_LIST_SUCCESS,
REPOSITORY_LIST_UPDATE,
REPOSITORY_LIST_FAIL,
FORUM_TAGS_LIST_REQUEST,
FORUM_TAGS_LIST_SUCCESS,
FORUM_TAGS_LIST_FAIL,

FORUM_LISTINSIGTHS_REQUEST,
FORUM_LISTINSIGTHS_SUCCESS,
FORUM_LISTINSIGTHS_FAIL,


FORUM3_DETAIL_REQUEST,
FORUM3_DETAIL_SUCCESS,
FORUM3_DETAIL_FAIL,



} from './../../constants/VdrConstants';

import {
    vdrLevelRequest,
    vdrGroupRequest,
    vdrCompanyRequest,
    vdrHomeRequest,
    vdrImageUploadRequest,
    geoLocationDb,
    vdrSingleCompanyRequest,
    vdrMediaDataRequest,
    vdrData20YearsRequest,
    vdrPeerToPeerRequest,
    vdrSortDataRequest,
    vdrSortDataUpdateRequest,
    vdrFavoriteRequest,
    MyBlogListRequest,
    vdrEmployeeRequest,
    vdrSegmentRequest,
    vdrAssignEmployeeRequest,
    vdrMediaRequest,
    VDRMediaMyFavouriteListRequest,
    filesLogRequest,
    priceActionRequest,
    vdrMediaGroupRequest,
    PriceIndexRequest,
    vdrForensicRequest,
    shareholdingRequest,
    boardOfDirectorDetailRequest,
    DateAPI,
    vdrSegmentAssignRequest,
    quarterlyReviewRequest,
    quarterlySegmentRequest,
    quarterlySegmentStandaloneRequest,
    dataRoomTickerRequest,
    forumRequest,
    forumDetailRequest,
    getNotification,
    QuarterlyResultsRequest,
    QuarterlySnapShotRequest,
    SingleCompProfitAndLossRequest,
    videoLikeRequest,
    VDRMediaAddToFavouriteRequest,
    videoCommentRequest,
    videoLikeCommentRequest,
    newsLetterCommentListRequest,
    RemoveToMylistRequest,
    AddToMylistRequest,
    newsLetterCommentAddRequest,
    newsletterLikeCommentRequest,
    SingleCompCashFlowRequest,
    SingleCompanyBalanceSheetRequest,
    SingleCompanyratiosRequest,
    SingleCompanypeersRequest,
    SingleNewsletterLikRequest,
    TLAnnualReportRequest,
    TLEarningsCallRequest,
    TLInvestorPresentationRequest,
    TLAnnouncementsRequest,
    DefaultMastersRequest,
    TLEOD_OHLCRRequest,
    ComparePeerRequest,
    PeerRatiosRequest,
    vdrPeerToPeerScRequest,
    RepositoryTestListRequest,
    TLOverviewRequest,
    TLEPSRequest,
    TLPBRequest,
    TLPERequest,
    TLQuarterlyResultsRequest,
    ResultDocumentNewRequest,
    ProfitAndLossChartRequest,
    forumTagsRequest,
    watchListRequest,
    watchListCompanyRequest,
    watchListBulkUploadRequest,

    forumInsiderRequest,
    forumDetailRequest3,


} from "../../utils/VdrRequest";
import { useSelector } from "react-redux";
import { omkaraUserListRequest } from "../../utils/userRequest";


export const PriceActionFilterAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: 'PriceActionFilterRequest' });
        dispatch({ type: 'PriceActionFilterSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        dispatch({ type: 'PriceActionFilterFailure', payload: errorMsg });
    }
};


export const priceActionFilters = (inputsArray) => {
    // console.log(inputsArray);
    const filterArray = {
        "Index": inputsArray.index?.value1,
        "Sector": inputsArray.sectors?.value1,
        "Industry": inputsArray.industry?.value1,
        "Portfolio":inputsArray.portfolio.value1,
        "Company": inputsArray.company?.value1,
        "Market_Cap": [inputsArray.Market_Cap?.value1, inputsArray.Market_Cap?.value2],
        "TTM_P_E": [inputsArray.TTM_PE?.value1, inputsArray.TTM_PE?.value2],
        "TTM_P_B": [inputsArray.TTM_PBV?.value1, inputsArray.TTM_PBV?.value2],
        "Chg_from_week52_highPer": inputsArray.Chg_from_week52_highPer?.value1,
        "Chg_from_week52_lowPer": inputsArray.Chg_from_week52_lowPer?.value1,
        "ChangeAllTimeHigh": inputsArray.ChangeAllTimeHigh?.value1
    }
    // const filterArray = {
    //     "Index": [
    //         "100"
    //     ],
    //     "Sector": [],
    //     "Industry": [],
    //     "Company": [],
    //     "Market_Cap": [],
    //     "TTM_P_E": [
    //         ">10"
    //     ],
    //     "TTM_P_B": [
    //         ">10"
    //     ],
    //     "Chg_from_week52_highPer": "",
    //     "Chg_from_week52_lowPer": "",
    //     "ChangeAllTimeHigh": ""
    // };
    return filterArray;
}

export const MessageAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: 'MessageRequest', payload: req });
    } catch (error) {
        console.log(error);
    }
};

export const GetOmakaraUsersAction = (req) => async (dispatch) => {
    try {
        const red = await omkaraUserListRequest();
        console.log('red >>> ', red.data);
        dispatch({ type: 'getOmakaraUsers', payload: red.data });
    } catch (error) {
        console.log(error);
    }
};

export const PriceActionAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: 'PriceActionRequest' });
        const { data } = await priceActionRequest(req);
        dispatch({ type: 'PriceActionSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'PriceActionFailure', payload: errorMsg });
    }
};


export const dateAPIAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'dateAPIRequest' });
        const { data } = await DateAPI();
        dispatch({ type: 'dateAPISuccess', payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'dateAPIFailure', payload: errorMsg });
    }
};


export const PriceIndexAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: 'PriceIndexRequest' });
        const { data } = await PriceIndexRequest(req);
        dispatch({ type: 'PriceIndexSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'PriceIndexFailure', payload: errorMsg });
    }
};


export const vdrGroupAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDR_GROUPS_REQUEST });
        const { data } = await vdrGroupRequest(req);
        dispatch({ type: VDR_GROUPS_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: VDR_GROUPS_FAIL, payload: errorMsg });
    }
};


export const vdrUpdateGroupAction = (req) => async (dispatch) => {

    try {
        dispatch({ type: VDR_GROUPS_REQUEST });
        const { data } = await vdrGroupRequest(req);
        dispatch({ type: VDR_GROUPS_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: VDR_GROUPS_FAIL, payload: errorMsg });
    }
};

export const vdrFavoriteAction = (req) => async (dispatch) => {

    
    try {
        
        dispatch({ type: FAVORITE_REQUEST });
        
        const { data } = await vdrFavoriteRequest(req);
        console.log(data);
        dispatch({ type: FAVORITE_SUCCESS, payload: data });
    } catch (error) {

        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        
        dispatch({ type: FAVORITE_FAIL, payload: errorMsg });
    }
};

export const getNotificationAction = (req) => async (dispatch) => {

    
    try {
        
        dispatch({ type: NOTIFICATION_REQUEST });
        
        const { data } = await getNotification(req);
        console.log(data);
        dispatch({ type: NOTIFICATION_SUCCESS, payload: data });
    } catch (error) {

        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        
        dispatch({ type: NOTIFICATION_FAIL, payload: errorMsg });
    }
};


export const vdrLevelAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDR_LEVEL_REQUEST });
        const { data } = await vdrLevelRequest(req);
        dispatch({ type: VDR_LEVEL_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: VDR_LEVEL_FAIL, payload: errorMsg });
    }
};




export const vdrCompanyAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDR_COMPANY_REQUEST });
        const { data } = await vdrCompanyRequest(req);
        // console.log('data', data);
        dispatch({ type: VDR_COMPANY_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;

        console.log(error);
        // if (error.response?.data?.Message) {
        //     errorMsg = error.response?.data?.Message;
        // } else {
        //     errorMsg = error.response?.data?.msg;
        // }
        dispatch({ type: VDR_COMPANY_FAIL, payload: errorMsg });
    }
};


export const vdrForensicAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'ForensicRequest' });
        const { data } = await vdrForensicRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'ForensicSuccess', payload: data });
        }else{
            dispatch({ type: 'ForensicSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;
        dispatch({ type: VDR_COMPANY_FAIL, payload: errorMsg });
    }
};


export const ShareholdingAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'ShareholdingRequest' });
        const { data } = await shareholdingRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'ShareholdingSuccess', payload: data });
        }else{
            dispatch({ type: 'ShareholdingSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;
        dispatch({ type: 'ShareholdingSuccess', payload: errorMsg });
    }
};


export const BoardOfDirectorDetailAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'BoardOfDirectorDetailRequest' });
        const { data } = await boardOfDirectorDetailRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'BoardOfDirectorDetailSuccess', payload: data });
        }else{
            dispatch({ type: 'BoardOfDirectorDetailSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;
        dispatch({ type: 'BoardOfDirectorDetailSuccess', payload: errorMsg });
    }
};


export const vdrAssignEmployeeAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'AssignEmployeeRequest' });
        const { data } = await vdrAssignEmployeeRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'AssignEmployeeSuccess', payload: data });
        }else{
            dispatch({ type: 'AssignEmployeeSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;

        console.log(error);
        // if (error.response?.data?.Message) {
        //     errorMsg = error.response?.data?.Message;
        // } else {
        //     errorMsg = error.response?.data?.msg;
        // }
        dispatch({ type: VDR_COMPANY_FAIL, payload: errorMsg });
    }
};


export const vdrMediaGroupAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'vdrMediaGroupRequest' });
        const { data } = await vdrMediaGroupRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'vdrMediaGroupSuccess', payload: data });
        }else{
            dispatch({ type: 'vdrMediaGroupSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;

        console.log(error);
        dispatch({ type: 'vdrMediaFail', payload: errorMsg });
    }
};


export const ComparePeerAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'ComparePeerRequest' });
        const { data } = await ComparePeerRequest(req);
        dispatch({ type: 'ComparePeerSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        // console.log(error);
        dispatch({ type: 'ComparePeerFail', payload: errorMsg });
    }
};

export const vdrMediaAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'vdrMediaRequest' });
        const { data } = await vdrMediaRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'vdrMediaSuccess', payload: data });
        }else{
            dispatch({ type: 'vdrMediaSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;

        console.log(error);
        // if (error.response?.data?.Message) {
        //     errorMsg = error.response?.data?.Message;
        // } else {
        //     errorMsg = error.response?.data?.msg;
        // }
        dispatch({ type: 'vdrMediaFail', payload: errorMsg });
    }
};
export const VDRMediaMyFavouriteListAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: VDRMediaMyFavouriteListREQUEST });
        const { data } = await VDRMediaMyFavouriteListRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: VDRMediaMyFavouriteListSUCCESS, payload: data });
        }else{
            dispatch({ type: VDRMediaMyFavouriteListSUCCESS, payload: data });
        }
    } catch (error) {
        var errorMsg;

        console.log(error);
        // if (error.response?.data?.Message) {
        //     errorMsg = error.response?.data?.Message;
        // } else {
        //     errorMsg = error.response?.data?.msg;
        // }
        dispatch({ type: VDRMediaMyFavouriteListFAIL, payload: errorMsg });
    }
};

export const filesLogAction = (req, actionFor) => async (dispatch) => {
    try {
        dispatch({ type: 'FilesLogRequest' });
        const { data } = await filesLogRequest(req);
        // console.log('data', data);
        if(actionFor == 'add'){
            dispatch({ type: 'FilesLogAdd', payload: data });
        }else{
            dispatch({ type: 'FilesLogSuccess', payload: data });
        }
    } catch (error) {
        var errorMsg;

        console.log(error);
        // if (error.response?.data?.Message) {
        //     errorMsg = error.response?.data?.Message;
        // } else {
        //     errorMsg = error.response?.data?.msg;
        // }
        dispatch({ type: 'FilesLogFailure', payload: errorMsg });
    }
};


export const vdrHomeAction = (req, req_type = "") => async (dispatch) => {
    try {
        console.log(req)
        dispatch({ type: VDR_HOME_REQUEST });
        const { data } = await vdrHomeRequest(req);
        if (req_type === 'filter') {
            dispatch({ type: VDR_HOME_SUCCESS_FILTER, payload: data });
        } else {
            dispatch({ type: VDR_HOME_SUCCESS, payload: data });
        }
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: VDR_HOME_FAIL, payload: errorMsg });
    }
};


export const vdrUploadImageAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDR_UPLOAD_IMAGE_REQUEST });
        const { data } = await vdrImageUploadRequest(req);
        dispatch({ type: VDR_UPLOAD_IMAGE_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: VDR_UPLOAD_IMAGE_FAIL, payload: errorMsg });
    }
};


export const vdrSingleCompanyAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDR_SINGLE_COMPANY_REQUEST });
        const { data } = await vdrSingleCompanyRequest(req);
        dispatch({ type: VDR_SINGLE_COMPANY_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        console.log(errorMsg);
        dispatch({ type: VDR_SINGLE_COMPANY_FAIL, payload: errorMsg });
    }
};

export const vdrMediaDataAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: MEDIA_DATA_REQUEST });
        const { data } = await vdrMediaDataRequest(req);
        dispatch({ type: MEDIA_DATA_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        // console.log('error',error.response);
        dispatch({ type: MEDIA_DATA_FAIL, payload: errorMsg });
    }
};

export const vdrData20YearsQuarterlyAction = (req) => async (dispatch) => {
    try {
        let params = req;
        dispatch({ type: DATA_20_YEARS_QUARTERLY_REQUEST });
        const { data } = await vdrData20YearsRequest(params);
        dispatch({ type: DATA_20_YEARS_QUARTERLY_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: DATA_20_YEARS_QUARTERLY_FAIL, payload: errorMsg });
    }
};

export const vdrData20YearsAction = (req) => async (dispatch) => {
    try {
        let params = req[0];
        params = {
            ...params,
            Type:'con'
        }
        params = [params]
        dispatch({ type: DATA_20_YEARS_REQUEST });
        const { data } = await vdrData20YearsRequest(params);
        dispatch({ type: DATA_20_YEARS_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: DATA_20_YEARS_FAIL, payload: errorMsg });
    }
};

export const vdrPeerToPeerAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: PEER_TO_PEER_REQUEST });
        const { data } = await vdrPeerToPeerRequest(req);
        dispatch({ type: PEER_TO_PEER_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: PEER_TO_PEER_FAIL, payload: errorMsg });
    }
};

export const vdrPeerToPeerScAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: PEER_TO_PEER_SC_REQUEST });
        const { data } = await vdrPeerToPeerScRequest(req);
        dispatch({ type: PEER_TO_PEER_SC_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: PEER_TO_PEER_SC_FAIL, payload: errorMsg });
    }
};

export const vdrSortDataAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SORT_DATA_REQUEST });
        const { data } = await vdrSortDataRequest(req);
        dispatch({ type: SORT_DATA_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: SORT_DATA_FAIL, payload: errorMsg });
    }
};

export const vdrSortDataUpdateAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SORT_DATA_UPDATE_REQUEST });
        const { data } = await vdrSortDataUpdateRequest(req);
        dispatch({ type: SORT_DATA_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: SORT_DATA_UPDATE_FAIL, payload: errorMsg });
    }
};

export const vdrEmployeeAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: EMPLOYEE_REQUEST });
        const { data } = await vdrEmployeeRequest();
        dispatch({ type: EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: EMPLOYEE_FAIL, payload: errorMsg });
    }
};

export const dataRoomTickerAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: DATAROOM_TICKER_REQUEST });
        const { data } = await dataRoomTickerRequest();
        console.log('data >> ', data)
        dispatch({ type: DATAROOM_TICKER_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: DATAROOM_TICKER_FAIL, payload: errorMsg });
    }
};


export const segmentAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SEGMENT_REQUEST });
        const { data } = await vdrSegmentRequest(req);
        dispatch({ type: SEGMENT_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: SEGMENT_FAIL, payload: errorMsg });
    }
};


export const segmentAssignAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SEGMENT_ASSIGN_REQUEST });
        const { data } = await vdrSegmentAssignRequest(req);
        dispatch({ type: SEGMENT_ASSIGN_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: SEGMENT_ASSIGN_FAIL, payload: errorMsg });
    }
};


export const quarterlyReviewAction = (req) => async (dispatch) => {
    // console.log('error')
    try {
        dispatch({ type: QUARTERLY_REVIEW_REQUEST });
        const { data } = await quarterlyReviewRequest(req);
        dispatch({ type: QUARTERLY_REVIEW_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: QUARTERLY_REVIEW_FAIL, payload: errorMsg });
    }
};

export const quarterlySegmentAction = (req, QuaterlySegmentType) => async (dispatch) => {
    // console.log('error')
    try {
        dispatch({ type: QUARTERLY_SEGMENT_REQUEST });
        let response;

        if(QuaterlySegmentType === "Standalone"){
            // response = await quarterlySegmentStandaloneRequest(req);
            req = {
                ...req,
                Type: "STD",
            }
        }else{
            req = {
                ...req,
                Type: "CON",
            }
            // response = await quarterlySegmentRequest(req);
        }
        response = await quarterlySegmentRequest(req);
        const { data } = response;
        console.log('data >>> ', data)
        dispatch({ type: QUARTERLY_SEGMENT_SUCCESS, payload: data, currentType:QuaterlySegmentType });
    } catch (error) {
        var errorMsg;
        console.log(error)
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: QUARTERLY_SEGMENT_FAIL, payload: errorMsg });
    }
};


export const publicIpAction = () => async (dispatch) => {
    try {
        dispatch({ type: PUBLIC_IP_REQUEST });
        const { data } = await geoLocationDb();
        console.log(data);
        dispatch({ type: PUBLIC_IP_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;

        console.log(error)
        dispatch({ type: PUBLIC_IP_FAIL, payload: error });
    }
};

export const openPDFAction = (itemLink) => async (dispatch) => {
    try {
        dispatch({ type: PDF_LINK_REQUEST });

        dispatch({ type: PDF_LINK_SUCCESS, payload: itemLink });
    } catch (error) {
        var errorMsg;
        console.log(error)
    }
};

export const mediaModelAction = (type) => async (dispatch) => {
    try {
        dispatch({ type: MEDIA_MODEL_REQUEST });
        dispatch({ type: MEDIA_MODEL_OPEN, payload: type });
    } catch (error) {
        var errorMsg;
        console.log(error)
    }
};





export const forumAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: FORUM_LIST_REQUEST });
        const { data } = await forumRequest(req);
        dispatch({ type: FORUM_LIST_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        console.log('errorMsg >>>>> ', error)
        dispatch({ type: FORUM_LIST_FAIL, payload: errorMsg });
    }
};




export const forumInsiderAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: FORUM_LISTINSIGTHS_REQUEST });
        const { data } = await forumInsiderRequest(req);
        dispatch({ type: FORUM_LISTINSIGTHS_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        console.log('errorMsg >>>>> ', error)
        dispatch({ type: FORUM_LISTINSIGTHS_FAIL, payload: errorMsg });
    }
};




export const forumTagsAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: FORUM_TAGS_LIST_REQUEST });
        const { data } = await forumTagsRequest(req);
        dispatch({ type: FORUM_TAGS_LIST_SUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: FORUM_TAGS_LIST_FAIL, payload: errorMsg });
    }
};



export const MyBlogListAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: MyBlogListREQUEST });
        const { data } = await MyBlogListRequest(req);
        dispatch({ type: MyBlogListSUCCESS, payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        console.log('errorMsg >>>>> ', error)
        dispatch({ type: MyBlogListFAIL, payload: errorMsg });
    }
};

export const forumDetailAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: FORUM_DETAIL_REQUEST });
        const { data } = await forumDetailRequest(req);
        
        dispatch({ type: FORUM_DETAIL_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: FORUM_DETAIL_FAIL, payload: errorMsg });
    }
};

// ============= Aakash =====================
export const forumDetailAction3 = (req) => async (dispatch) => {
    try {
        dispatch({ type: FORUM3_DETAIL_REQUEST });
        const { data } = await forumDetailRequest3(req);
        
        dispatch({ type: FORUM3_DETAIL_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        let errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: FORUM3_DETAIL_FAIL, payload: errorMsg });
    }
};
// ============= Aakash =====================


export const QuarterlyResultsAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: QUARTERLY_RESULTS_REQUEST, payload: req });
        const { data } = await QuarterlyResultsRequest(req);
        
        dispatch({ type: QUARTERLY_RESULTS_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: QUARTERLY_RESULTS_FAIL, payload: errorMsg });
    }
};


export const QuarterlySnapShotAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: QUARTERLY_SNAPSHOT_REQUEST });
        const { data } = await QuarterlySnapShotRequest(req);
        
        dispatch({ type: QUARTERLY_SNAPSHOT_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: QUARTERLY_SNAPSHOT_FAIL, payload: errorMsg });
    }
};


export const SingleCompProfitAndLossAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_COMP_PROFIT_LOSS_REQUEST });
        const { data } = await SingleCompProfitAndLossRequest(req);
        
        dispatch({ type: SINGLE_COMP_PROFIT_LOSS_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SINGLE_COMP_PROFIT_LOSS_FAIL, payload: errorMsg });
    }
};


export const SingleCompCashFlowAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_COMP_CASHFLOW_REQUEST });
        const { data } = await SingleCompCashFlowRequest(req);
        
        dispatch({ type: SINGLE_COMP_CASHFLOW_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SINGLE_COMP_CASHFLOW_FAIL, payload: errorMsg });
    }
};

export const SingleCompanyBalanceSheetAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SingleCompanyBalanceSheetREQUEST });
        const { data } = await SingleCompanyBalanceSheetRequest(req);
        
        dispatch({ type: SingleCompanyBalanceSheetSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SingleCompanyBalanceSheetFAIL, payload: errorMsg });
    }
};

export const SingleCompanyratiosAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SingleCompanyratiosREQUEST });
        const { data } = await SingleCompanyratiosRequest(req);
        
        dispatch({ type: SingleCompanyratiosSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SingleCompanyratiosFAIL, payload: errorMsg });
    }
};

export const SingleCompanypeersAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SingleCompanypeersREQUEST });
        const { data } = await SingleCompanypeersRequest(req);
        
        dispatch({ type: SingleCompanypeersSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SingleCompanypeersFAIL, payload: errorMsg });
    }
};

export const VideoLikeAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VIDEO_LIKE_REQUEST });
        const { data } = await videoLikeRequest(req);
        
        dispatch({ type: VIDEO_LIKE_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: VIDEO_LIKE_FAIL, payload: errorMsg });
    }
};
export const VDRMediaAddToFavouriteAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VDRMediaAddToFavouriteREQUEST });
        const { data } = await VDRMediaAddToFavouriteRequest(req);
        
        dispatch({ type: VDRMediaAddToFavouriteSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: VDRMediaAddToFavouriteFAIL, payload: errorMsg });
    }
};

export const VideoCommentAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VIDEO_COMMENT_REQUEST });
        const { data } = await videoCommentRequest(req);
        
        dispatch({ type: VIDEO_COMMENT_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: VIDEO_COMMENT_FAIL, payload: errorMsg });
    }
};

export const VideoCommentLikeAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: VIDEO_COMMENT_LIKE_REQUEST });
        const { data } = await videoLikeCommentRequest(req);
        
        dispatch({ type: VIDEO_COMMENT_LIKE_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: VIDEO_COMMENT_LIKE_FAIL, payload: errorMsg });
    }
};

export const NewsletterLikeAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: NEWSLETTER_LIKE_REQUEST });
        const { data } = await videoLikeRequest(req);
        
        dispatch({ type: NEWSLETTER_LIKE_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: NEWSLETTER_LIKE_FAIL, payload: errorMsg });
    }
};

export const NewsletterCommentAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: NEWSLETTER_COMMENT_REQUEST });
        const { data } = await newsLetterCommentListRequest(req);
        
        dispatch({ type: NEWSLETTER_COMMENT_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: NEWSLETTER_COMMENT_FAIL, payload: errorMsg });
    }
};
export const RemoveToMylistAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: RemoveToMylistREQUEST });
        const { data } = await RemoveToMylistRequest(req);
        
        dispatch({ type: RemoveToMylistSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: RemoveToMylistFAIL, payload: errorMsg });
    }
};
export const AddToMylistAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: AddToMylistREQUEST });
        const { data } = await AddToMylistRequest(req);
        
        dispatch({ type: AddToMylistSUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: AddToMylistFAIL, payload: errorMsg });
    }
};

export const NewsletterAddCommentAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: NEWSLETTER_COMMENT_REQUEST });
        const { data } = await newsLetterCommentAddRequest(req);
        
        dispatch({ type: NEWSLETTER_COMMENT_ADD, payload: data });
    } catch (error) {
        console.log('error ><>>>>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: NEWSLETTER_COMMENT_FAIL, payload: errorMsg });
    }
};

export const NewsletterCommentLikeAction = (req) => async (dispatch) => {
    try {
        // dispatch({ type: NEWSLETTER_COMMENT_LIKE_REQUEST });
        const { data } = await newsletterLikeCommentRequest(req);
        
        dispatch({ type: NEWSLETTER_COMMENT_1_LIKE_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
    }
};

export const PeerRatiosAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: PEER_RATIOS_REQUEST });
        const { data } = await PeerRatiosRequest(req);
        
        dispatch({ type: PEER_RATIOS_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: PEER_RATIOS_FAIL, payload: errorMsg });
    }
};

export const SingleNewsletterLikAction = (req) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_NEWSLETTER_LIKE_REQUEST });
        const { data } = await SingleNewsletterLikRequest(req);
        dispatch({ type: SINGLE_NEWSLETTER_LIKE_SUCCESS, payload: data });
    } catch (error) {
        console.log('error >>>> ', error)
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response;
        } else {
            errorMsg = error.response;
        }
        dispatch({ type: SINGLE_NEWSLETTER_LIKE_FAIL, payload: errorMsg });
    }
};



export const DefaultMastersAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'DefaultMasterRequest' });
        const { data } = await DefaultMastersRequest();
        dispatch({ type: 'DefaultMasterSuccess', payload: data?.data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error?.response?.data?.Message;
        } else {
            errorMsg = error?.response?.data?.msg;
        }
        dispatch({ type: 'DefaultMasterFailure', payload: errorMsg });
    }
};




export const RepositoryListAction = (params) => async (dispatch) => {
  
    // dispatch({ type: REPOSITORY_LIST_RESET });
    try {
      dispatch({ type: REPOSITORY_LIST_REQUEST });
      const { data } = await RepositoryTestListRequest(params);
      dispatch({ type: REPOSITORY_LIST_SUCCESS, payload: data });
    } catch (error) {
      console.log(error)
      dispatch({ type: REPOSITORY_LIST_FAIL, payload: error.response });
    }
    
  };
  


// TL API



export const TLAnnualReportAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'AnnualReportRequest' });
        const { data } = await TLAnnualReportRequest(compSlug, params);
        // console.log('dataINFY >>> ', data?.body?.newsList)
        let rData = data?.body?.newsList ? data?.body?.newsList : []
        dispatch({ type: 'AnnualReportSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'AnnualReportFailure', payload: errorMsg });
    }
};


export const TLEarningsCallAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'EarningsCallRequest' });
        const { data } = await TLEarningsCallRequest(compSlug, params);
        let rData = data?.body?.newsList ? data?.body?.newsList : []
        dispatch({ type: 'EarningsCallSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'EarningsCallFailure', payload: errorMsg });
    }
};


export const TLInvestorPresentationAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'InvestorPresentationRequest' });
        const { data } = await TLInvestorPresentationRequest(compSlug, params);
        let rData = data?.body?.newsList ? data?.body?.newsList : []
        dispatch({ type: 'InvestorPresentationSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'InvestorPresentationFailure', payload: errorMsg });
    }
};

export const TLAnnouncementsAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'AnnouncementsRequest' });
        const { data } = await TLAnnouncementsRequest(compSlug, params);
        let rData = data?.body?.newsList ? data?.body?.newsList : []

        dispatch({ type: 'AnnouncementsSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'AnnouncementsFailure', payload: errorMsg });
    }
};



export const TLEOD_OHLCRAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'EOD_OHLCRRequest' });
        const { data } = await TLEOD_OHLCRRequest(compSlug, params);
        let rData = data?.body?.EodData ? data?.body?.EodData : []
        dispatch({ type: 'EOD_OHLCRSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'EOD_OHLCRFailure', payload: errorMsg });
    }
};
// TLEPSRequest
// TLPBRequest
// TLPERequest

export const TLEPSAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'EPSRequest' });
        const { data } = await TLEPSRequest(compSlug, params);
        let rData = data?.body?.EPSChartData ? data?.body?.EPSChartData : []

        dispatch({ type: 'EPSSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'EPSFailure', payload: errorMsg });
    }
};
export const TLPBAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'PBRequest' });
        const { data } = await TLPBRequest(compSlug, params);
        let rData = data?.body?.PBVChartData ? data?.body?.PBVChartData : []
        dispatch({ type: 'PBSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'PBFailure', payload: errorMsg });
    }
};
export const TLPEAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'PERequest' });
        const { data } = await TLPERequest(compSlug, params);
        // console.log('data?.body?.PEChartData >>>> ', data?.body?.PEChartData)
        let rData = data?.body?.PEChartData ? data?.body?.PEChartData : []
        dispatch({ type: 'PESuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'PEFailure', payload: errorMsg });
    }
};


export const TLOverviewAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'OverviewRequest' });
        const { data } = await TLOverviewRequest(compSlug);
        
        dispatch({ type: 'OverviewSuccess', payload: data?.body });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'OverviewFailure', payload: errorMsg });
    }
};


export const TLQuarterlyResultsAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'QuarterlyResultsRequest' });
        const { data } = await TLQuarterlyResultsRequest(compSlug);
        dispatch({ type: 'QuarterlyResultsSuccess', payload: data?.body });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'QuarterlyResultsFailure', payload: errorMsg });
    }
};


export const ResultDocumentNewAction = (params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'ResultDocumentNewRequest' });
        const { data } = await ResultDocumentNewRequest(params);
        dispatch({ type: 'ResultDocumentNewSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'ResultDocumentNewFailure', payload: errorMsg });
    }
};

export const ProfitAndLossChartAction = (params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'ProfitAndLossChartRequest' });
        const { data } = await ProfitAndLossChartRequest(params);
        dispatch({ type: 'ProfitAndLossChartSuccess', payload: data });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'ProfitAndLossChartFailure', payload: errorMsg });
    }
};







// START WISHLIST



export const watchListAction = (params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'watchListRequest' });
        const { data } = await watchListRequest(params);
        console.log('data >>> ', data)
        let rData = data
        dispatch({ type: 'watchListSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'watchListFailure', payload: errorMsg });
    }
};

export const watchListCompanyAction = (compSlug, params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'watchListCompanyRequest' });
        const { data } = await watchListCompanyRequest(compSlug, params);
        let rData = data;
        dispatch({ type: 'watchListCompanySuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'watchListCompanyFailure', payload: errorMsg });
    }
};


export const watchListBulkUploadAction = (params={}) => async (dispatch) => {
    try {
        dispatch({ type: 'watchListBulkUploadRequest' });
        const { data } = await watchListBulkUploadRequest(params);
        let rData = data;
        dispatch({ type: 'watchListBulkUploadSuccess', payload: rData });
    } catch (error) {
        var errorMsg;
        if (error.response?.data?.Message) {
            errorMsg = error.response?.data?.Message;
        } else {
            errorMsg = error.response?.data?.msg;
        }
        dispatch({ type: 'watchListBulkUploadFailure', payload: errorMsg });
    }
};


// END WISHLIST

