import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';

const filter = createFilterOptions();

export default function SearchComponent({itemList, SearchWidth, insigths}) {
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };
  const navigate = useNavigate();

  return (
    <React.Fragment>

    <Autocomplete
        // freeSolo
        id="free-solo-2-demo"
        disableClearable
        size="small"
        options={itemList}
        onChange={(event, newValue) => {
          // console.log('newValue >>> ', newValue)
          // navigate(   insights ? "/insights" : "/blogs" `/${newValue?.year}`)
          navigate(`${insigths ? "/insights" : "/blogs"}/${newValue?.year}`);
          setValue(newValue);
      }}

        sx={{ width: SearchWidth, borderColor: "#000" }}
        getOptionLabel={(option) => option.title}
        // renderOption={(props, option) => <li {...props}>{option.title}</li>}
        renderInput={(params) => (
          <>
            <TextField size="small" {...params} placeholder="Search..." className='aaaaaa' sx={{ borderColor: "#000" }} />
            {/* <TextField
              {...params}
              label="Search input"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            /> */}
          </>
        )}
      />

      
      {/* <Autocomplete
        value={value}
        size='small'
        onChange={(event, newValue) => {
            console.log('newValue >>> ', newValue)
            navigate(`/blogs/${newValue?.year}`)
         
        setValue(newValue);
        
        }}
        
        id="free-solo-dialog-demo"
        options={itemList}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        sx={{ width: 325, borderColor: "#000" }}
        renderInput={(params) => <TextField size="small" {...params} label="Search..." className='aaaaaa' sx={{ borderColor: "#000" }} />}
      /> */}
    </React.Fragment>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
];